
import en from 'antd/locale/en_US';
import es from 'antd/locale/es_ES';
import pt from 'antd/locale/pt_PT';

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';



import { decodeBase64URLToken } from "@utils/helper.js";
import { StorageKeys } from "@utils/LocalStorage";
import { getLocalStorageItem } from "@utils/storagelocal.js";
import { FEATURE_NAME } from '@common/redux/constants';
import { ProfileModel } from "@common/domain/models/Profile";
import { setFirstTimeUserAction } from "@common/redux/auth/actions";
import { IAttendee } from "@common/redux/meeting/interface";
import { showProfileModal, updateProfileDetails } from "@common/redux/profile/actions";

import { IUserModel } from '@models/Login';

import { AppLanguage } from "@translations/AppLanguage";
import { i18n } from '@translations/i18n';

import Navigator from '@navigator/index';
import { NavigationUrl, URLs } from '@navigator/NavigationUrl';

import { ENGLISH_MONTHS_ABBR, PORTUGUESE_MONTHS_ABBR, PORTUGUESE_MONTH_NAMES, 
    SPANISH_MONTHS_ABBR, userRoles, platform, PRODUCT_TOURS, FEATURE_TOGGLE, DEFAULT_LANG_CODE } from '@constants/config';
import { ModuleLevelActivityStatus } from '@constants/ReportConfig';

import { getRequiredIncompleteFields } from "@presentation/Profile/components/ProfileFields/utils";

import { WebStoreProviderService } from "@store/WebStoreProviderService";

import { getLanguage } from "./LanguageUtils";
import * as crypto from 'crypto';
import dayjs from 'dayjs';


export const getLoggedInUserId = () => {
    const token = getLocalStorageItem(StorageKeys.ACCESS_TOKEN);
    let userId = undefined;
    if (!!token) {
        const userIdSub = decodeBase64URLToken(token);
        userId = userIdSub.sub;
    }
    return userId;
}

export const getUserUuid = () => {
    return getLocalStorageItem(StorageKeys.USER_UUID);
}

export const getUserRole = () => {
    const role = localStorage.getItem(StorageKeys.USER_ROLE);
    return (role === 'null') ? "" : role
}

export const getUserLang = () => {
    const language = localStorage.getItem(StorageKeys.PREFERRED_LANGUAGE);
    return (language === 'null') ? "" : language
}

export const setUserRole = (user) => {
    const role = user.profile && user.profile.role && user.profile.role[0];
    if (role) {
        localStorage.setItem(StorageKeys.USER_ROLE, role);
    }
};

export const storeUserData = (loginData: any) => {
    const { preferences } = loginData;

    const accessToken = loginData?.accessToken;
    const userData = decodeBase64URLToken(accessToken);
    const emailId: string = userData?.email;
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken);
    localStorage.setItem(StorageKeys.REFRESH_TOKEN, loginData.refreshToken);
    localStorage.setItem(StorageKeys.IS_PROFILE_COMPLETED, preferences.isProfileCompleted);
    localStorage.setItem(StorageKeys.IS_EMAIL_VERIFIED, preferences.isEmailVerified);
    localStorage.setItem(StorageKeys.PREFERRED_LANGUAGE, preferences.preferredLanguage);
    localStorage.setItem(StorageKeys.USER_ROLE, ((loginData.role && loginData.role[0]) || loginData.role) as string);
    localStorage.setItem(StorageKeys.TEMP_PASS_GENERATED, preferences.tempPasswordGenerated);
    localStorage.setItem(StorageKeys.EMAIL, emailId.toLowerCase());
    localStorage.setItem(StorageKeys.USER, JSON.stringify(loginData));
    localStorage.setItem(StorageKeys.IS_TERMS_AND_CONDITIONS_ACCEPTED, preferences.isTermsAndConditionsAccepted);
    localStorage.setItem(StorageKeys.SHOW_APP_NUDGE, "true");
};

export const authCheck = () => {
    const user: IUserModel = getUserModal();
    if (!user || !user.preferences) {
        // if no data
        return;
    }
    const { isProfileCompleted, tempPasswordGenerated } = user.preferences;
    setFirstTimeUserFlag(user);

    if (tempPasswordGenerated === 'true') {
        Navigator.replace(NavigationUrl.generate(URLs.changePassword));
    } else if ((isProfileCompleted === 'false' || !isProfileCompleted) && !getUserRole()) {
        Navigator.replace(NavigationUrl.generate(URLs.createProfile));
    };
};



export const handleAuthFlow = (currentPath?: string, profile?: ProfileModel) => {
    const user: IUserModel = getUserModal();
    if (!user || !user.preferences) {
        // if no data
        return;
    }

    const { isProfileCompleted, tempPasswordGenerated } = user.preferences;
    const isAuthenticated = WebStoreProviderService?.getStore()?.getState()?.auth?.isAuthenticated;
    setFirstTimeUserFlag(user);
    //@ts-ignore
    if (platform.WITP) {
        if (tempPasswordGenerated === 'true') {
            Navigator.replace(NavigationUrl.generate(URLs.changePassword));
        }
        else if (isProfileCompleted === 'false' && getUserRole() !== userRoles.faculty) {
            Navigator.replace(NavigationUrl.generate(URLs.createProfile));
        }        
        else if ((isProfileCompleted || isProfileCompleted === 'true' || !getUserRole()) && profile) {
            checkAndHandleIncompleteStudentProfile(profile);
        }
        else if (isAuthenticated) {
            Navigator.replace(NavigationUrl.generate(URLs.home));
        }
    } else if (platform.WO) {
        if (tempPasswordGenerated === 'true') {
            const changePasswordOnboardingStepRoute = NavigationUrl.generate(URLs.changePassword);
            (!currentPath || (currentPath && !currentPath.includes(changePasswordOnboardingStepRoute))) && Navigator.replace(changePasswordOnboardingStepRoute);
        }

        else if ((!isProfileCompleted || isProfileCompleted === 'false') && !getUserRole()) {
            const createProfileOnbaordingStepRoute = NavigationUrl.generate(URLs.createProfile);
            (!currentPath || (currentPath && !currentPath.includes(createProfileOnbaordingStepRoute))) && Navigator.replace(createProfileOnbaordingStepRoute);
        }
        else if ((isProfileCompleted || isProfileCompleted === 'true' || !getUserRole()) && profile) {
            checkAndHandleIncompleteStudentProfile(profile);
        }
        else if (!currentPath && !profile && isAuthenticated) {
            const homeRoute = NavigationUrl.generate(URLs.home);
            (!currentPath || (currentPath && !currentPath.includes(homeRoute))) && Navigator.replace(homeRoute);
        }
    }

}


export const setFirstTimeUserFlag = (user: IUserModel) => {

    const { isProfileCompleted, tempPasswordGenerated } = user.preferences;

    if (
        tempPasswordGenerated === 'true' ||
        ((!isProfileCompleted || isProfileCompleted === 'false') && !getUserRole())
    ) {
        WebStoreProviderService.getStore().dispatch(setFirstTimeUserAction(true));
    }
}


export const checkAndHandleIncompleteStudentProfile = (profile: ProfileModel) => {

    const { incompleteFields } = getRequiredIncompleteFields(profile);
    if (incompleteFields.length > 0)
        WebStoreProviderService.getStore().dispatch(showProfileModal(true));

}

export const updateTempPasswordFlag = (value: string) => {
    localStorage.setItem(StorageKeys.TEMP_PASS_GENERATED, value);
    localStorage.setItem(StorageKeys.IS_TERMS_AND_CONDITIONS_ACCEPTED, "true");
    const user = getUserModal();
    if (user) {
        user.preferences.tempPasswordGenerated = value;
        user.isTermsAndConditionsAccepted = "true";
        updateUserModal(user);
    }
};

export const getUserModal = () => {
    const user = localStorage.getItem(StorageKeys.USER);
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

export const updateUserModal = (user: IUserModel) => {
    localStorage.setItem(StorageKeys.USER, JSON.stringify(user));
}

export const getUserEmail = () => {
    return localStorage.getItem(StorageKeys.EMAIL);
};

export const getUserName = ()=>{
    return localStorage.getItem(StorageKeys.USER_NAME)
}

export const updateProfileCompletedFlag = (value: string) => {
    localStorage.setItem(StorageKeys.IS_PROFILE_COMPLETED, value);
    // update role to student
    localStorage.setItem(StorageKeys.USER_ROLE, userRoles.student);

    const user = getUserModal();
    if (user) {
        user.preferences.isProfileCompleted = value;
        updateUserModal(user);
    }
}

export const getTermsAndConditions = () => {
    return localStorage.getItem(StorageKeys.IS_TERMS_AND_CONDITIONS_ACCEPTED);
};

export const getAttendeesList = (attendees: ProfileModel[]): IAttendee[] => {
    return (
        attendees.map((student: ProfileModel) => {
            return {
                userId: student.getUserId(),
                organizer: student.getUserId() === getLoggedInUserId(),
                email: student.getEmail(),
            }
        })
    )
};

export const getCalendarLanguage = () => {
    switch (getLanguage()) {
        case AppLanguage.Spanish: {
            return es;
        }
        case AppLanguage.Portuguese: {
            return pt;
        }
        case AppLanguage.English: {
            return en;
        }
        default:
            return en;
    }
}

export const sortMembersByRole = (members) => {
    return members.sort((a, b) => {
        let first = a.getRole().toLowerCase();
        let second = b.getRole().toLowerCase();

        if (first < second)
            return -1;
        if (first > second)
            return 1;
        return 0;
    });
}

export const getModuleLevelActivityStatusForView = (status: ModuleLevelActivityStatus) => {
    switch (status) {
        case `${ModuleLevelActivityStatus.NotAttemptedLessons}`:
            return i18n.t(`report.labelsAndTitles.NOT_ATTEMPTED_LESSONS`);
        case ModuleLevelActivityStatus.AttemptedLessons:
            return i18n.t(`report.labelsAndTitles.ATTEMPTED_LESSONS`);
        case ModuleLevelActivityStatus.CompletedLessons:
            return i18n.t(`report.labelsAndTitles.COMPLETED_LESSONS`);
        default:
            return "";
    }
}

export const getEducationQualification = (education) => {
    switch (education) {
        case 'Below 10th':
            return i18n.t(`auth.labelsAndTitles.BELOW_TENTH`);
        case 'Below 12th':
            return i18n.t(`auth.labelsAndTitles.BELOW_TWELFTH`);
        case 'ITI/Polytechnic':
            return i18n.t(`auth.labelsAndTitles.POLYTECHNIC`);
        case 'College Dropout':
            return i18n.t(`auth.labelsAndTitles.COLLEGE_DROPOUT`);
        case 'Graduate':
            return i18n.t(`auth.labelsAndTitles.GRADUATE`);
        default:
            return "";
    }
}

export const getLanguagesForView = (language) => {
    switch (language) {
        case 'English':
            return i18n.t(`global.labelsAndTitles.ENGLISH`);
        case 'English-US':
            return i18n.t(`global.labelsAndTitles.ENGLISH_US`);
        case 'Spanish':
            return i18n.t(`global.labelsAndTitles.SPANISH`);
        case 'Portuguese':
            return i18n.t(`global.labelsAndTitles.PORTUGUESE`);
        default:
            return "";
    }
}

export const getLanguageFromCode = (languageCode) => {
    switch (languageCode) {
        case AppLanguage.English:
            return i18n.t(`global.labelsAndTitles.ENGLISH`);
        case AppLanguage.Portuguese:
            return i18n.t(`global.labelsAndTitles.PORTUGUESE`);
        case AppLanguage.Spanish:
            return i18n.t(`global.labelsAndTitles.SPANISH`);
        default:
            return i18n.t(`global.labelsAndTitles.ENGLISH`);
    }
}

export const getUserLanguages = (language) => {
    switch (language) {
        case 'Inglês':
            return 'English';
        case 'Inglês-EUA':
            return 'English-US';
        case 'Espanhola':
            return 'Spanish';
        case 'Português':
            return 'Portuguese';
        default:
            return "";
    }
}

export const storeSocialMediaUserInfo = (userInfo) => {
    localStorage.setItem(StorageKeys.SOCIAL_MEDIA_USER_INFO, JSON.stringify(userInfo));
}




export const getHmacKey = (clientKey: string, msg: string) => {
    return crypto.createHmac('sha256', clientKey).update(msg).digest('hex');
}

export const getZoomIDFromZoomMeetingURL = (url: string) => {
    const regex = /\/j\/(\d+)/;
    const match = url.match(regex);

    if (match) {
        const id = match[1];
        return id
    }
}

export const formStringWithAllDependenciesAppended = (str: string, dependencies: any) => {
    for (const key in dependencies) {
        if (dependencies.hasOwnProperty(key)) {
            const placeholder = new RegExp(`\\{${key}\\}`, 'g');
            const value = dependencies[key];
            str = str.replace(placeholder, value);
        }
    }
    return str;
}



export const makeUpdateProfileApiCall = (userProfile:ProfileModel, product_tour:string, product_tour_status:string,) => {

    const aiCoachTour = {
        name: product_tour,
        acknowledgedAt: new Date().toISOString(),
        status: product_tour_status
    };

    const existingAiCoachIndex = 
        userProfile.getProductTours().findIndex(tour => tour.name === product_tour);

    const updatedProductTours = existingAiCoachIndex === -1
        ? [...userProfile.getProductTours(), aiCoachTour]
        : userProfile.getProductTours().map((tour, index) =>
            index === existingAiCoachIndex ? aiCoachTour : tour
        );


    //make api call and update profile data (acknowledge)
    const apiParams = {
        firstName: userProfile.getFirstName(),
        lastName: userProfile.getLastname(),
        email: userProfile.getEmail(),
        productTours: updatedProductTours
    }
    WebStoreProviderService.getStore().dispatch(updateProfileDetails(apiParams, getLoggedInUserId()));
}


export const isFeatureEnabled = (featureName: string, profileInfo: ProfileModel) => {
    /* For language specific changes*/
    // const lang = profileInfo?.getPreferredLanguage();
    const betaFeatures:string[] = profileInfo?.getBetaFeatures() || [];
    let returnVal = false;
  
   switch (featureName) {
  
    case FEATURE_NAME.AICOACH : 
        returnVal = (FEATURE_TOGGLE.aiCoach ||  betaFeatures.includes(featureName) );
        break;
  
    case FEATURE_NAME.INTERVIEW_PREP:
        returnVal = (FEATURE_TOGGLE.interviewPrep  ||  betaFeatures.includes(featureName));
        break;

    case FEATURE_NAME.FORUM:
        returnVal = FEATURE_TOGGLE.forums;
        break;

    case FEATURE_NAME.CAREERS:
        returnVal = FEATURE_TOGGLE.careers;
        break;

    case FEATURE_NAME.LIBRARY:
        returnVal = FEATURE_TOGGLE.library;
        break;
  
    default:    
        returnVal = false;
        break;
    }
    return returnVal;
  
  }

  export const adjustLanguage = (language: string): string => {
    let returnVal = language;
    switch (language) {
        case 'pt':
            returnVal = 'pt-BR';
        case 'hi':
            returnVal = DEFAULT_LANG_CODE;
    }
    return returnVal;
}

